import React, { useState, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';

const CustomAutocomplete = ({
  options,
  value,
  onChange,
  placeholder,
  getDropdownOptions,
  dropdownId, // Unique ID for each dropdown
  openDropdownId, // Shared state for tracking open dropdown
  setOpenDropdownId, // Function to update the open dropdown
}) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  // Handle outside click to close the dropdown
  useEffect(() => {
    const handleOutsideClick = e => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        openDropdownId === dropdownId
      ) {
        setOpenDropdownId(null);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [openDropdownId, dropdownId, setOpenDropdownId]);

  const debouncedFetchOptions = debounce(input => {
    getDropdownOptions(input);
  }, 300);

  const handleSearchInputChange = e => {
    const input = e.target.value;
    setSearchInputValue(input);

    if (input) {
      debouncedFetchOptions(input);
    } else {
      setFilteredOptions(options);
    }
  };

  const handleOptionClick = option => {
    onChange(option);
    setSearchInputValue(''); // Clear the search input after selection
    setOpenDropdownId(null); // Close the dropdown
  };

  const toggleDropdown = () => {
    setOpenDropdownId(openDropdownId === dropdownId ? null : dropdownId);
  };

  return (
    <div
      className="relative w-full"
      ref={dropdownRef}
    >
      {/* Main Input */}
      <div
        className="relative flex w-full items-center rounded border border-gray-800 p-2"
        onClick={toggleDropdown}
      >
        <div className="flex-grow text-black min-h-6">{value || placeholder}</div>

        {/* Dropdown Arrow */}
        <div className="ml-2 flex-shrink-0 cursor-pointer">
          {openDropdownId === dropdownId ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 text-gray-800"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 15l6-6 6 6"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 text-black"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 9l6 6 6-6"
              />
            </svg>
          )}
        </div>
      </div>

      {/* Dropdown List */}
      {openDropdownId === dropdownId && (
        <div className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded border border-gray-500 bg-white text-black">
          {/* Search Input in Dropdown */}
          <div className="relative flex items-center border-b border-gray-700 p-2">
            <input
              type="text"
              value={searchInputValue}
              onChange={handleSearchInputChange}
              placeholder="Search..."
              className="w-full rounded border border-gray-800 bg-white p-2 pl-8 text-black focus:outline-none placeholder-gray-800"
            />
            {/* Search Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 transform text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-4.35-4.35M10 18a8 8 0 100-16 8 8 0 000 16z"
              />
            </svg>
          </div>

          {/* Filtered Options */}
          <ul className="max-h-40 overflow-auto">
            {filteredOptions.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option)}
                className="cursor-pointer p-2 hover:bg-gray-800 hover:text-white"
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomAutocomplete;
