import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Input from '../Input';
import { getKeys, formatKeysForInput } from './utils';

/**
 * HotkeyField
 * Renders a hotkey input that records keys
 *
 * @param {object} props component props
 * @param {Array[]} props.keys keys to be controlled by this field
 * @param {boolean} props.disabled disables the field
 * @param {function} props.onChange callback with changed values
 * @param {string} props.className input classes
 * @param {Array[]} props.modifierKeys
 */
const HotkeyField = ({ disabled = false, keys, onChange, className, modifierKeys, hotkeys }) => {
  const [isFocused, setIsFocused] = useState(false); // Track focus state
  const [isMouseClick, setIsMouseClick] = useState(false); // Track mouse button press
  const inputValue = formatKeysForInput(keys);

  const mouseButtonMap = {
    0: 'Left Click',
    1: 'Middle Click',
    2: 'Right Click',
  };

  const onInputKeyDown = event => {
    // If it's a keyboard key, record it
    if (isFocused && !isMouseClick) {
      hotkeys.record(sequence => {
        const keys = getKeys({ sequence, modifierKeys });
        hotkeys.unpause();
        onChange(keys);
      });
    }
  };

  const onMouseDown = event => {
    // If mouse button clicked, set the hotkey
    if (isFocused && mouseButtonMap[event.button]) {
      setIsMouseClick(true); // Mark mouse click is happening
      onChange([mouseButtonMap[event.button]]);
      hotkeys.unpause(); // End the pause when mouse click occurs
    }
  };

  const onFocus = () => {
    // Set focus to true, but don't start recording yet
    setIsFocused(true);
    hotkeys.pause(); // Pause hotkey recording initially
  };

  const onBlur = () => {
    // When the input loses focus, reset the states
    setIsFocused(false);
    setIsMouseClick(false);
  };

  return (
    <Input
      readOnly
      disabled={disabled}
      value={inputValue}
      onKeyDown={onInputKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseDown={onMouseDown}
      className={className}
    />
  );
};

HotkeyField.propTypes = {
  keys: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  modifierKeys: PropTypes.array,
  disabled: PropTypes.bool,
  hotkeys: PropTypes.shape({
    initialize: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    unpause: PropTypes.func.isRequired,
    startRecording: PropTypes.func.isRequired,
    record: PropTypes.func.isRequired,
  }).isRequired,
};

export default HotkeyField;
